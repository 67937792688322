<template>
  <div :class="size ? dimensions.classes : ''">
    <RevButtonBase
      v-if="showPlaceHolder"
      class="relative w-full"
      data-test="youtube-facade"
      v-bind="placeholderAttrs"
      @click="showPlaceHolder = false"
    >
      <ImageRenderer
        v-if="image"
        :alt="title"
        :height="dimensions.height"
        :src="image.src"
        :src-mobile="image.srcMobile"
        :width="dimensions.width"
      />

      <ImageRenderer
        v-else
        :alt="title"
        :height="dimensions.height"
        :src="`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`"
        :src-mobile="`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`"
        :width="dimensions.width"
      />

      <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <RevIllustration
          alt=""
          :height="48"
          src="/img/cms/YoutubePlay.svg"
          :width="68"
        />
      </div>
    </RevButtonBase>

    <iframe
      v-else
      allow="autoplay; encrypted-media"
      allowfullscreen
      class="aspect-video w-full"
      data-test="youtube-iframe"
      frameborder="0"
      loading="lazy"
      :src="`https://www.youtube.com/embed/${youtubeId}?autoplay=1`"
      :title
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type { Image } from '@backmarket/http-api/src/api-specs-content/models/image'
import type { YoutubeVideo } from '@backmarket/http-api/src/api-specs-content/models/video'
import { tw } from '@backmarket/utils/string/tw'
import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import ImageRenderer from '../ImageRenderer/ImageRenderer.vue'

const props = defineProps<{
  youtubeId: string
  title?: string
  placeholderAttrs?: Record<string, unknown>
  size?: YoutubeVideo['size']
  image?: Image
}>()

const showPlaceHolder = ref<boolean>(true)

const dimensions = computed(() => {
  if (props.size === 'medium') {
    return {
      height: 630,
      width: 1120,
      classes: tw`h-[184px] md:h-[630px] w-[1120px]`,
    }
  }

  return {
    height: 415,
    width: 736,
    classes: tw`h-[184px] md:h-[415px] w-[736px]`,
  }
})
</script>
